<template>
<div id="header">
    <ul class="navigation">
        <li @click="goto('/')" :class="isSelected('/')" class="d-flex align-items-center">
            <inline-svg class="mr-2" :src="svg('symbol.svg')" width="30" height="30" />
            <div>Hem</div>
        </li>
        <li @click="goto('/tjanster')" :class="isSelected('/tjanster')" class="d-flex align-items-center">
            <inline-svg class="mr-2" :src="svg('gift.svg')" width="25" height="25" />
            <div>Våra tjänster</div>
        </li>
        <li @click="goto('/kontakt')" :class="isSelected('/kontakt')" class="d-flex align-items-center">
            <inline-svg class="mr-2" :src="svg('phone.svg')" width="25" height="25" />
            <div>Kontakt</div>
        </li>
    </ul>
</div>
</template>

<script>
export default {
    name: 'Menu',
    data() {
        return {
            visible: false,
            avatar_flip: false
        }
    },
    methods: {
        goto(path) {
            this.$router.push(path);
        },
        isSelected(path, startswith) {
            if (startswith) {
                return this.$route.path.startsWith(path) ? "nav-active" : "";
            } else {
                return path == this.$route.path ? "nav-active" : "";
            }
        },
        svg(path) {
            return this.$images.svg(path)
        }
        // avatar() {
        //     return require("@/assets/images/avatar.jpg");
        // },
        // showMenu() {
        //     this.visible = !this.visible;
        // }
    }
}
</script>
